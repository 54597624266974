/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import LinkedInIcon from "../../content/assets/linkedin.svg";
import InstagramIcon from "../../content/assets/instagram.svg";
import TwitterIcon from "../../content/assets/twitter.svg";
import FacebookIcon from "../../content/assets/facebook.svg";
import YouTubeIcon from "../../content/assets/youtube.svg";

const Bio = props => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "profile" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                width: 120
                height: 120
                quality: 100
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
            social {
              youtube
              linkedIn
              twitter
              facebook
              instagram
            }
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author =
    data.site.siteMetadata?.author.find(auth =>
      auth.name.includes(props.author)
    ) ?? data.site.siteMetadata?.author[0];
  const social = author?.social ?? data.site.siteMetadata?.social;
  const edge = data?.avatar?.edges?.find(edge =>
    author?.name?.includes(edge.node.base.split(".")[0])
  );
  const avatar = edge?.node?.childImageSharp?.gatsbyImageData;
  const openProfile = url => {
    window.open(url, "_blank");
  };
  return (
    <div className="bio">
      {avatar && (
        <div className="animate-pulse-once my-auto h-24 w-24 sm:h-28 sm:w-28 bg-gradient-to-tr from-primary to-fushia-600 rounded-full mr-4">
          <GatsbyImage
            image={avatar}
            className="rounded-full ring-4 ring-white m-2"
            alt={author?.name || ``}
            imgClassName="rounded-full ring-4 ring-white m-2"
          />
        </div>
      )}
      {author?.name && (
        <div className="flex-1 font-sans">
          <p>
            Written by <strong>{author.name}</strong>
            {` `}
            {/* <a href={`https://twitter.com/${social?.twitter || ``}`}>
            You should follow him on Twitter
          </a> */}
          </p>
          <p>{author?.summary || null}</p>
          <p>Follow me:</p>
          <p className="bio-social">
            {social &&
              Object.keys(social).map(
                account =>
                  social[account] && (
                    <button
                      key={account}
                      aria-label={`Visit my ${account} profile`}
                      onClick={() => openProfile(social[account])}
                    >
                      {account === "youtube" && social[account] && (
                        <YouTubeIcon alt={`${social[account]}`} />
                      )}
                      {account === "linkedIn" && social[account] && (
                        <LinkedInIcon alt={`${social[account]}`} />
                      )}
                      {account === "twitter" && social[account] && (
                        <TwitterIcon />
                      )}
                      {account === "facebook" && social[account] && (
                        <FacebookIcon />
                      )}
                      {account === "instagram" && social[account] && (
                        <InstagramIcon />
                      )}
                    </button>
                  )
              )}
          </p>
        </div>
      )}
    </div>
  );
};

export default Bio;
