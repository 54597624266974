import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

const src = "https://utteranc.es/client.js";
const branch = "main";
const LIGHT_THEME = "github-light";

const Comments = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            commentsRepo
          }
        }
      }
    `
  );

  const repo = site.siteMetadata?.commentsRepo;

  const rootElm = React.createRef();

  useEffect(() => {
    const utterances = document.createElement("script");
    const utterancesConfig = {
      src,
      repo,
      branch,
      theme: LIGHT_THEME,
      label: "comment",
      async: true,
      "issue-term": "pathname",
      crossorigin: "anonymous",
    };

    Object.keys(utterancesConfig).forEach(configKey => {
      utterances.setAttribute(configKey, utterancesConfig[configKey]);
    });
    rootElm.current.appendChild(utterances);
  }, []);

  return <div className="utterances" ref={rootElm} />;
};

export default Comments;
