import React from "react";

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
} from "react-share";

const SocialShare = ({ url, title, description, tags }) => (
  <div className="container my-4 space-x-1">
    <p className="my-2">Liked this post? Share it with others</p>
    <FacebookShareButton url={url} title={title}>
      <FacebookIcon size={38} round={true} />
    </FacebookShareButton>
    <TwitterShareButton
      url={url}
      title={title}
      via={"gaurav_sen_"}
      hashtags={tags?.length > 0 ? tags.split(" ") : null}
    >
      <TwitterIcon size={38} round={true} />
    </TwitterShareButton>

    <LinkedinShareButton url={url} title={title} summary={description}>
      <LinkedinIcon size={38} round={true} />
    </LinkedinShareButton>

    <RedditShareButton url={url} title={title}>
      <RedditIcon size={38} round={true} />
    </RedditShareButton>

    <WhatsappShareButton url={url} title={title}>
      <WhatsappIcon size={38} round={true} />
    </WhatsappShareButton>
    <TelegramShareButton url={url} title={title}>
      <TelegramIcon size={38} round={true} />
    </TelegramShareButton>
    <EmailShareButton
      url={url}
      subject={title}
      body={`Found this useful article - "${title}"`}
    >
      <EmailIcon size={38} round={true} />
    </EmailShareButton>
  </div>
);

export default SocialShare;
